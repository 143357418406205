<template>
  <a-card :bordered="false">
    <a-row>
      <a-col :span="16">
        <a-descriptions title="基本信息" :column="2" >
          <a-descriptions-item label="医生姓名">{{ this.info.trueName }}</a-descriptions-item>
          <a-descriptions-item label="性别">{{ this.info.sex | sexFilter }}</a-descriptions-item>
          <a-descriptions-item label="出生年月">{{ this.info.birthday }}</a-descriptions-item>
          <a-descriptions-item label="电话">{{ this.info.mobile }}</a-descriptions-item>
          <a-descriptions-item label="邮箱">{{ this.info.email }}</a-descriptions-item>
          <a-descriptions-item label="状态">{{ this.info.status| statusFilter }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <img
          width="100"
          height="100"
          slot="cover"
          alt="example"
          :src="'/web/attr/show?attr_id='+this.info.headerurl"
        />
      </a-col>
    </a-row>

    <a-descriptions title="专业相关信息" :column="2" >
      <a-descriptions-item label="地区">{{ this.info.regionName }}</a-descriptions-item>
      <a-descriptions-item label="医院">{{ this.info.nosoName }}</a-descriptions-item>
      <a-descriptions-item label="科室">{{ this.info.depName }}</a-descriptions-item>
      <a-descriptions-item label="职称">{{ this.info.position }}</a-descriptions-item>
      <a-descriptions-item label="教学职称">{{ this.info.lecturerName }}</a-descriptions-item>
      <a-descriptions-item label="医院行政职务">{{ this.info.administrativePost }}</a-descriptions-item>
      <a-descriptions-item label="社会任职">{{ this.info.societyName }}</a-descriptions-item>
      <a-descriptions-item label="擅长">{{ this.info.specialty }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ this.info.remarks }}</a-descriptions-item>
    </a-descriptions>

  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getById } from '@/api/doctor'
export default {
  components: {
    STable
  },
  data () {
    return {
      doctorId: 0,
      info: {}
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        1: '启用',
        2: '禁用'

      }
      return statusMap[status]
    },
    sexFilter (level) {
      const statusMap = {
        1: '男',
        2: '女'
      }
      return statusMap[level]
    }
  },
  methods: {
    setDetailValue (value) {
      this.doctorId = value
      this.getInfo(value)
    },
    getInfo (value) {
      getById({ doctorId: value }).then(res => {
        this.info = res.result
      })
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  color: rgba(0,0,0,.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
